<template>
	<b-modal id="edit-company" size="lg" title="Edit Company" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>
							<b-row class="my-3">
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Name" label-for="name" description>
										<b-form-input id="name" name="Name" type="text" v-model="form.name" v-validate="{
											required: true,
											regex: companyNameRegex,
										}" placeholder="Name" readonly />
										<span v-show="errors.has('Name')" class="help-block">
											{{ errors.first('Name') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Description" label-for="description" description="">
										<b-form-input id="description" name="Description" type="text"
											v-model="form.description" v-validate="{
												required: false,
												regex: remarksRegex,
											}" placeholder="Description" maxlength="50" />
										<span v-show="errors.has('Description')" class="help-block">
											{{ errors.first('Description') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Type" label-for="type">
										<b-form-select id="type" name="Type" v-model="form.type" v-validate="'required'"
											:options="companyTypes" />
										<span v-show="errors.has('Type')" class="help-block">
											{{ errors.first('Type') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Industry" label-for="">
										<v-select id="industry" name="Industry" class="style-chooser" label="text"
											:options="industryOptions" :reduce="(industry) => industry.value"
											v-model="form.industry" v-validate="'required'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a industry
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Industry')" class="help-block">
											{{ errors.first('Industry') }}
										</span>
									</b-form-group>
									<b-form-group label="" label-for="" description="* Please specify here"
										v-show="industryOthersIsHidden">
										<b-form-input id="industryOthers" name="Industry Others"
											v-model="form.industryOthers" v-validate="{
												required: companyDetailIsRequired,
												regex: /^([a-zA-Z0-9&\v, \-])*$/,
											}" :disabled="industryOthersIsDisabled" />
										<span v-show="errors.has('Industry Others')" class="help-block">
											{{ errors.first('Industry Others') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Has Parent Company?">
										<b-form-radio-group name="Has Parent Company" v-model="form.hasParentCompany"
											v-validate="'required'" :options="yesNoOptions" plain
											:disabled="hasParentCompanyIsDisabled" />
										<span v-show="errors.has('Has Parent Company')" class="help-block">
											{{ errors.first('Has Parent Company') }}
										</span>
									</b-form-group>
									<b-form-group label="" label-for=""
										description="* Please select the parent company here"
										v-if="form.hasParentCompany" :disabled="hasParentCompanyIsDisabled">
										<v-select name="Parent Company" class="style-chooser" label="text"
											:options="companyOptions" :reduce="(company) => company.value"
											v-model="selCompany" v-validate="'selectRequired'"
											:disabled="hasParentCompanyIsDisabled">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Parent Company')" class="help-block">
											{{ errors.first('Parent Company') }}
										</span>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12" v-show="isAllowedToActivate()">
									<b-form-group label="Status">
										<b-form-radio-group name="Status" v-model="form.isActive"
											:options="statusOptions" />
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Address">
							<b-row class="my-2">
								<b-col sm="8">
									<b>ADDRESS</b>
								</b-col>
							</b-row>
							<b-row class="my-3">
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Main Office Address" label-for="address"
										description="* Please enter the complete company's main office address">
										<b-form-input id="address" name="Address" type="text" v-model="form.address"
											v-validate="{
												required: true,
												regex: addressRegex,
											}" required placeholder="Address" />
										<span v-show="errors.has('Address')" class="help-block">
											{{ errors.first('Address') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-4">
								<b-col sm="12">
									<b-form-group label="Geographical Location" label-for="geoaddress"
										description="* This is the exact coordinates that will be used to locate in the map">
										<b-row class="my-2">
											<b-col lg="4" md="6" sm="12">
												<b-form-group description="Latitude">
													<b-form-input id="latitude" name="Latitude" type="number"
														class="numFont" v-model="geoaddress.latitude"
														v-validate="'required|min_value:-90|max_value:90'" min="-90"
														max="90" step="0.01" onwheel="this.blur()" placeholder="0" @keydown="blockInvalidCharacters" />
												</b-form-group>
												<span v-show="errors.has('Latitude')" class="help-block">
													{{ errors.first('Latitude') }}
												</span>
											</b-col>
											<b-col lg="4" md="6" sm="12">
												<b-form-group description="Longitude">
													<b-form-input id="longitude" name="Longitude" type="number"
														class="numFont" v-model="geoaddress.longitude"
														v-validate="'required|min_value:-180|max_value:180'" min="-180"
														max="180" step="0.01" onwheel="this.blur()" placeholder="0" @keydown="blockInvalidCharacters" />
												</b-form-group>
												<span v-show="errors.has('Longitude')" class="help-block">
													{{ errors.first('Longitude') }}
												</span>
											</b-col>
											<b-col lg="4" md="6" sm="12">
												<b-link href="https://www.latlong.net/" target="_blank">
													<i class="icon-info"></i>&nbsp;Generate Coordinates
													Here
												</b-link>
											</b-col>
										</b-row>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Contact Details">
							<b-row class="my-2">
								<b-col sm="8">
									<b>CONTACT DETAILS</b>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Contact Person" label-for="contactPerson" description>
										<b-form-input id="contactPerson" name="Contact Person" type="text"
											v-model="form.contactPerson" v-validate="{
												required: false,
												regex: fullNameRegex,
											}" placeholder="Name" maxlength="100" />
										<span v-show="errors.has('Contact Person')" class="help-block">
											{{ errors.first('Contact Person') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Email" label-for="emailAddress" description>
										<b-form-input id="email" name="Email" type="email" v-model="form.emailAddress"
											v-validate="{
												regex: emailRegex,
											}" placeholder="Email Address" />
										<span v-show="errors.has('Email')" class="help-block">
											{{ errors.first('Email') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Contact No." label-for="contactNo"
										description="Can be in mobile number or landline format">
										<b-form-input id="contactNo" name="Contact No" class="numFont"
											v-model="form.contactNo" onwheel="this.blur()" v-validate="{
												required: false,
												regex: contactNoRegex,
											}" placeholder="+639xxxxxxxxx | (XX) XXXX XXXX" maxlength="50" />
										<span v-show="errors.has('Contact No')" class="help-block">
											{{ 'Allowed format: +639xxxxxxxxx or (XX) XXXX XXXX' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscriptions">
							<b-row class="my-2">
								<b-col sm="8">
									<b>SUBSCRIPTION DETAILS</b>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Company Code" label-for="brand"
										description="Use for brand naming of the company asset types">
										<b-form-input id="brand" name="Company Code" type="text" v-model="form.brand"
											v-validate="{
												required: companyCodeIsRequired,
												regex: brandRegex,
											}" placeholder :disabled="companyDetailIsDisabled" />
										<span v-show="errors.has('Company Code')" class="help-block">
											{{ errors.first('Company Code') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Branch Code" label-for="branchCode" description="">
										<b-form-input id="branchCode" name="Branch Code" type="text"
											v-model="form.branchCode" v-validate="{
												required: false,
												regex: branchCodeRegex,
											}" placeholder="Branch Code" maxlength="10" />
										<span v-show="errors.has('Branch Code')" class="help-block">
											{{ errors.first('Branch Code') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="No. of Storage Locations" label-for="noOfStorageLocations"
										description>
										<b-form-input id="noOfStorageLocations" name="No of Storage Locations"
											type="number" class="numFont" onwheel="this.blur()"
											v-model="form.noOfStorageLocations" v-validate="{
												required: true,
												max_value: 10000,
												min_value: 0,
											}" placeholder />
										<span v-show="errors.has('No of Storage Locations')" class="help-block">
											{{ errors.first('No of Storage Locations') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="No. of Inactive Locations" label-for="noOfInactiveNodes"
										description>
										<b-form-input id="noOfInactiveNodes" name="No of Inactive Nodes" type="number"
											class="numFont" onwheel="this.blur()" v-model="form.noOfInactiveNodes"
											v-validate="{
												required: companyDetailIsRequired,
												max_value: 10000,
												min_value: 0,
											}" placeholder :disabled="companyDetailIsDisabled" />
										<span v-show="errors.has('No of Inactive Nodes')" class="help-block">
											{{ errors.first('No of Inactive Nodes') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="No. of Users" label-for="noOfUsers" description>
										<b-form-input id="noOfUsers" name="No of Users" type="number" class="numFont"
											onwheel="this.blur()" v-model="form.noOfUsers" v-validate="{
												required: companyDetailIsRequired,
												max_value: 10000,
												min_value: 0,
											}" placeholder :disabled="companyDetailIsDisabled" />
										<span v-show="errors.has('No of Users')" class="help-block">
											{{ errors.first('No of Users') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12" v-if="loggedUser.type === 'Super Admin'">
									<b-form-group label="Use 2-Way Auth?">
										<b-form-radio-group id="has2WayAuth" name="Use 2-Way Auth"
											v-model="form.has2WayAuth" v-validate="'required'"
											:options="yesNoOptions" />
										<span v-show="errors.has('Use 2-Way Auth')" class="help-block">
											{{ errors.first('Use 2-Way Auth') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="mt-4 mb-2" v-if="loggedUser.type === 'Super Admin'">
								<b-col sm="8">
									<b>CAMERA OPTIONS</b>
								</b-col>
							</b-row>
							<b-row class="mt-3" v-if="loggedUser.type === 'Super Admin'">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Use Top Camera?">
										<b-form-radio-group id="useTopCamera" name="Top Camera"
											v-model="form.camera.top" v-validate="'required'" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Use Back Camera?">
										<b-form-radio-group id="useBackCamera" name="Use Back Camera"
											v-model="form.camera.back" v-validate="'required'"
											:options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="mt-4 mb-2">
								<b-col sm="8">
									<b>COMPANY LOGO</b>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Company Logo" label-for="company-logo"
										description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
										<b-form-file id="company-logo" placeholder="Choose image"
											ref="companyLogofileInput" accept="image/png, image/jpeg, image/jpg"
											@change="onSelectCompanyLogo($event)">
											<template slot="file-name" slot-scope="{ names }">
												<b-badge variant="dark">{{ names[0] }}</b-badge>
												<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
													names.length - 1 }} More files</b-badge>
											</template>
										</b-form-file>
									</b-form-group>

									<!-- Image Preview -->
									<div v-if="companyLogo.url">
										<b-card overlay :img-src="companyLogo.url" img-alt="Card Image"
											text-variant="white" class="mb-0">
											<b-card-text class="text-right">
												<b-button variant="danger" size="sm" @click="onRemoveCompanyLogo()">
													<em class="fa fa-trash"></em>
												</b-button>
											</b-card-text>
										</b-card>
										<b-progress v-if="uploading.uploadStatus === 'uploading'"
											:value="uploading.percentage" :max="uploading.filename" variant="success"
											height="8px" animated />
									</div>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Permissions" v-if="loggedUser.type === 'Super Admin'">
							<b-row class="my-2">
								<b-col sm="8">
									<b>PERMISSIONS</b>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Asset Pooling"
										description="For handling asset pooling access basic or premium subscription">
										<b-form-radio-group id="permission-asset-pooling" name="Asset Pooling"
											v-model="form.permissions.pooling" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Asset Pooling')" class="help-block">
											{{ errors.first('Asset Pooling') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Content Management System"
										description="For web access to all account information and reports">
										<b-form-radio-group id="permission-cms" name="Content Management System"
											v-model="form.permissions.cms" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Content Management System')" class="help-block">
											{{ errors.first('Content Management System') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Inventory"
										description="For monitoring asset inventory">
										<b-form-radio-group id="permission-inventory" name="Inventory"
											v-model="form.permissions.inventory" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Inventory')" class="help-block">
											{{ errors.first('Inventory') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Dispatch"
										description="For monitoring asset exchanges between companies">
										<b-form-radio-group id="permission-dispatch" name="Dispatch"
											v-model="form.permissions.dispatch" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Dispatch')" class="help-block">
											{{ errors.first('Dispatch') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Transport"
										description="For managing trucker accountability on each delivery">
										<b-form-radio-group id="permission-transport" name="Transport"
											v-model="form.permissions.transport" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Transport')" class="help-block">
											{{ errors.first('Transport') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-show="form.type !== 'Asset User'">
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Maintenance"
										description="For handling asset repairs; NOT applicable to Asset User company type">
										<b-form-radio-group id="permission-maintenance" name="Maintenance"
											v-model="form.permissions.maintenance" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Maintenance')" class="help-block">
											{{ errors.first('Maintenance') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-show="form.type !== 'Asset User'">
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Billing"
										description="For handling asset rental billings; NOT applicable to Asset User company type">
										<b-form-radio-group id="permission-billing" name="Billing"
											v-model="form.permissions.billing" v-validate="'required'"
											:options="yesNoOptions" plain />
										<span v-show="errors.has('Billing')" class="help-block">
											{{ errors.first('Billing') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Utils
import { CompanyUtil } from '@/utils/companyUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import companyApi from '@/api/companyApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
	name: 'edit-company',
	components: {
		Loading,
	},
	props: {
		allIndustriesObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: { ...CompanyUtil.getDefaultCompanyObj() },
			companyTypes: config.companyTypes,
			industryOptions: [],
			statusOptions: [
				{ text: 'Active', value: 'true' },
				{ text: 'Inactive', value: 'false' },
			],
			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],
			companyOptions: [],

			selCompany: { ...config.companyDefaultValue },
			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allUsersObj: {},

			geoaddress: {
				latitude: 0.0,
				longitude: 0.0,
			},
			companyLogo: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		companyDetailIsRequired() {
			return this.form.isActive === 'true';
		},
		companyDetailIsDisabled() {
			return this.form.isActive !== 'true';
		},
		hasParentCompanyIsDisabled() {
			return !this.isSuperAdmin;
		},
		industryOthersIsHidden() {
			return this.form.industry === 'Others';
		},
		industryOthersIsDisabled() {
			return this.form.industry !== 'Others';
		},
		companyCodeIsRequired() {
			return this.companyDetailIsRequired && this.form.type === 'Asset Service Provider';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
		companyNameRegex() {
			return config.companyNameRegex;
		},
		fullNameRegex() {
			return config.fullNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		contactNoRegex() {
			return config.contactNoRegex;
		},
		emailRegex() {
			return config.emailRegex;
		},
		addressRegex() {
			return config.addressRegex;
		},
		brandRegex() {
			return config.brandRegex;
		},
		branchCodeRegex() {
			return config.branchCodeRegex
		},
	},
	watch: {
		'form.type'(newVal) {
			if (newVal === 'Asset User') {
				// set permission these permission to false by default
				this.form.permissions.maintenance = false;
				this.form.permissions.billing = false;
			}
		},
	},
	methods: {
		blockInvalidCharacters(event) {
            // Check if the key pressed corresponds to one of the blocked characters
            if (event.key === '+' || event.key === '-' || event.key === 'e') {
                // Prevent the default behavior (typing the character)
                event.preventDefault();
            }
        },
		isAllowedToActivate() {
			return this.loggedUserCompany.id !== this.form.id;
		},
		hasValidNoOfStorageLocations(newLocNo) {
			let activeLocationsArr = _.filter(this.allStorageLocationsObj, (o) => {
				return o.isActive === 'true';
			});
			let prevLocNo = _.size(activeLocationsArr);
			return newLocNo >= prevLocNo && newLocNo > 0;
		},

		hasValidNoOfUsers(newUserNo) {
			let activateUsersArr = _.filter(this.allUsersObj, (o) => {
				return o.isActive === 'true';
			});
			let prevUserNo = _.size(activateUsersArr);
			return newUserNo >= prevUserNo && newUserNo > 0;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			this.cleanupFormFields();
			this.updateParentCompanySelection();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			} else if (ValidationUtil.objectHasField('name', this.form.name, this.allCompaniesObj)
					&& this.form.name !== this.$store.getters.currCompany.name) {
				this.$toaster.warning('Company name already exists.');
				this.isLoading = false;
				return;
			} else if (this.form.name === this.form.parentCompany) {
				this.$toaster.warning('Invalid parent company. Company must not have the same name of its parent company.');
				this.isLoading = false;
				return;
			} else if (!this.form.camera.top && !this.form.camera.back) {
				this.$toaster.warning('At least one camera should have access.');
				this.isLoading = false;
				return;
			} else if (ValidationUtil.objectHasField('brand', this.form.brand, this.allCompaniesObj)
					&& this.form.brand !== this.$store.getters.currCompany.brand) {
				this.$toaster.warning('Company code already exists.');
				this.isLoading = false;
				return;
			} else if (!CompanyUtil.hasAtLeast1Permission(this.form)) {
				this.$toaster.warning('At least one permission should have been subscribed.');
				this.isLoading = false;
				return;
			} else if (!this.hasValidNoOfStorageLocations(this.form.noOfStorageLocations)) {
				this.$toaster.warning('Number of storage locations must be equal or greater than the registered number of active company storage locations.');
				this.isLoading = false;
				return;
			} else if (!this.hasValidNoOfUsers(this.form.noOfUsers)) {
				this.$toaster.warning('Number of users must be equal or greater than the registered number of active company users.');
				this.isLoading = false;
				return;
			} else if (this.loggedUser.companyId === this.form.id && this.form.isActive === 'false') {
				this.$toaster.warning('You can\'t deactivate your company account that is currently logged-in.');
				this.isLoading = false;
				return;
			} 

			await this.handleSubmit();
		},
		cleanupFormFields() {
			// Removes excess whitespace
			this.form.industryOthers = ValidationUtil.removeExcessWhiteSpace(
				this.form.industryOthers
			);
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
			this.form.address = ValidationUtil.removeExcessWhiteSpace(
				this.form.address
			);
			this.form.contactPerson = ValidationUtil.removeExcessWhiteSpace(
				this.form.contactPerson
			);

			if (this.form.industry === 'Others') {
				this.form.industry = this.form.industryOthers;
			}
		},
		updateParentCompanySelection() {
			if (this.selCompany.id && this.form.hasParentCompany) {
				this.form.parentCompany = this.selCompany.name;
				this.form.parentCompanyId = this.selCompany.id;
			} else {
				this.form.parentCompany = '';
				this.form.parentCompanyId = '';
			}
		},

		getCompanyObj(param) {
			return {
				id: param.id,
				name: param.name,
				description: param.description,
				type: param.type,
				industry: param.industry,
				hasParentCompany: param.hasParentCompany
					? param.hasParentCompany
					: false,
				parentCompany: param.parentCompany ? param.parentCompany : '',
				parentCompanyId: param.parentCompanyId ? param.parentCompanyId : '',
				isActive: param.isActive,
				address: param.address,
				geoaddress: LocationUtil.getGeoaddress(this.geoaddress),
				contactPerson: param.contactPerson,
				contactNo: param.contactNo,
				emailAddress: param.emailAddress,
				brand: param.brand,
				branchCode: param.branchCode,
				noOfStorageLocations: param.noOfStorageLocations ? parseInt(param.noOfStorageLocations) : 0,
				noOfInactiveNodes: param.noOfInactiveNodes ? parseInt(param.noOfInactiveNodes) : 0,
				noOfUsers: param.noOfUsers ? parseInt(param.noOfUsers) : 0,
				has2WayAuth: param.has2WayAuth,
				camera: param.camera,
				permissions: param.permissions,
				companyLogo: param.companyLogo,
				dateCreated: param.dateCreated,
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let companyObj = this.getCompanyObj(this.form);
				let { data } = await companyApi.saveCompany(
					companyObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					// upload company logo
					if (!_.isEmpty(this.companyLogo)) {
						data.company = await this.uploadCompanyLogo(data.company);
					} else {
						data.company.companyLogo = {};
					}

					this.$toaster.success(`Company "${this.name}" was updated successfully.`);
					EventBus.$emit('onCloseEditCompany', data);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error updating company "${this.name}". Please try again.`);
				}

			} catch (error) {
				this.$toaster.error(`Error updating company "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async uploadCompanyLogo(company) {
			let image = this.companyLogo;
			if (image._isNew) {
				const uploadResult = await this.firebaseUploadImage(image, company);
				if (uploadResult.name && uploadResult.url) {
					let logoDetails = {
						id: company.id,
						logo: uploadResult,
					};

					let { data } = await companyApi.updateCompanyLogo(
						logoDetails,
						this.loggedUser.id,
						DateUtil.getCurrentTimestamp()
					);

					if (data.isSuccess) {
						company = data.company;
						this.uploadingInProgress('success');
					}
				}
			}

			return company;
		},
		async firebaseUploadImage(mediaFile, company) {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			let filename = `COMPANYLOGO_${company.name}_${currTimeStamp}.png`;

			this.uploadingInProgress('uploading', filename, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${mediaFile.fbStoragePath}/${filename}`);
				let task = storageRef.put(mediaFile.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.uploadingInProgress('uploading', filename, percentage);
					},
					(error) => {
						reject(error);
						this.resetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: filename,
								url: downloadURL,
							});
						});
					}
				);
			});
		},

		uploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},

		resetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},

		onRemoveCompanyLogo() {
			this.companyLogo = {};
			this.$refs['companyLogofileInput'].reset();
		},

		onSelectCompanyLogo(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG format.'
				);
				this.companyLogo = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w != dimensions.h) {
					vm.$toaster.warning("Company logo's width and height should be the same.");
				} else if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning("Company logo's width and height shouldn't be greater than 1000 pixels.");
				} else if (file.size > 1024 * 1024) {
					vm.$toaster.warning("The image size must be less than 1 MB.");
				} else {
					vm.companyLogo = {
						url: url,
						file: file,
						fbStoragePath: 'images/companyLogos',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		onReset() {
			this.resetUploadingState();
			this.resetDropdownValues();

			// init form
			this.form = { ...this.$store.getters.currCompany };
			this.form = CompanyUtil.cleanupFields(this.form);

			// init parent company
			this.selCompany = { ...config.companyDefaultValue };
			if (this.form.hasParentCompany) {
				let parentCompanyId = this.form.parentCompanyId;
				this.selCompany = DropDownItemsUtil.getCompanyItem(this.allCompaniesObj[parentCompanyId]);
			}

			// init geoaddreess
			this.geoaddress = { ...this.form.geoaddress };

			// init company logo
			if (!this.form.companyLogo || _.isEmpty(this.form.companyLogo)) {
				this.$set(this.form, 'companyLogo', {});
			}
			const companyLogo = !_.isEmpty(this.form.companyLogo)
				? this.form.companyLogo
				: {};
			this.companyLogo = { ...companyLogo };

			// init branchCode
			this.form.branchCode = this.form.branchCode ? this.form.branchCode : '';

			// init camera options
			if (!this.form.camera || _.isEmpty(this.form.camera)) {
				this.form.camera = { ...config.cameraDefaultValues };
			}

			// init permission
			if (!this.form.permissions || _.isEmpty(this.form.permissions)) {
				this.form.permissions = { ...config.permissionsDefaultValues };
			} else {
				this.form.permissions = { ...this.form.permissions };
			}

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
			this.resetValidationDependencies(this.form);
		},
		resetDropdownValues() {
			this.allCompaniesObj = { ...this.$store.getters.companies };
			this.companyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

			this.industryOptions = DropDownItemsUtil.retrieveIndustries(
				this.allIndustriesObj,
				true
			);
		},
		resetValidationDependencies(company) {
			this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };
			let storageLocationsArr = _.filter(this.allStorageLocationsObj, (o) => {
				return o.companyId === company.id;
			});
			this.allStorageLocationsObj = _.keyBy(storageLocationsArr, 'id');

			this.allUsersObj = { ...this.$store.getters.users };
			let usersArr = _.filter(this.allUsersObj, (o) => {
				return o.companyId === company.id;
			});
			this.allUsersObj = _.keyBy(usersArr, 'id');
		},
	},
};
</script>