import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
  if (config.currEnv === 'pallet-pooling') {
      return `${config.currEnv}`;
  } else {
      return `ayun-quantity-${config.currEnv}`;
  }
}

export default {
  baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

  newCompany(companyId, currUserId) {
    const url = `${this.baseUrl}/newCompany`;
    return axios.post(url, { companyId, currUserId });
  }

}